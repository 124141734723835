import { errorManagement } from '@/error/errorManagement.error'
import { initStore } from '.'

/**
 * Wraps an asynchronous function with try-catch for error handling and automatically manages loading state.
 * This function is designed to be used with asynchronous operations, encapsulating them with a loading state
 * management and centralized error handling.
 * 
 * @param fn - The asynchronous function to be wrapped. This function can accept any number of arguments.
 * @returns A new asynchronous function that, when called, will manage loading state before and after the
 * original ```typescript
/**
 * Wraps an asynchronous function with try-catch for error handling and automatically manages loading state.
 * This function is designed to be used with asynchronous operations, encapsulating them with a loading state
 * management and centralized error handling.
 * 
 * @param fn The asynchronous function to be wrapped. This function can accept any number of arguments.
 * @returns A new asynchronous function that wraps the original function. When invoked, it manages the loading
 * state before and after the execution of the original function, and handles any errors that occur during its execution.
 */
export function catchAsync(fn: Function) {
  return async (...args: any[]) => {
    const { appStore } = initStore('appStore')
    try {
      // Activates the loading state before executing the function
      appStore.updateLoading(true)
      // Executes the function and waits for the result
      return await fn(...args)
    } catch (error) {
      // Handles the error with errorManagement
      return errorManagement(error)
    } finally {
      // Ensures the loading state is updated even in case of an error
      appStore.updateLoading(false)
    }
  }
}
