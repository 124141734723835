import { catchAsync, initStore } from '.'
import config from '../config'
import type { jsonResponseInterface, AppErrorInterface } from '../interfaces'
import type { requestHttpType } from '../types/types'

/**
 * Creates an AJAX configuration object for use with the fetch API.
 *
 * This function generates a configuration object that includes HTTP method, headers,
 * body (if provided), a timeout signal, and credentials settings based on the environment.
 * The credentials are included only when the environment is set to production.
 *
 * @param method - The HTTP method to use for the request (e.g., GET, POST, PUT, etc.).
 * @param body - An optional object representing the request body. If present, it will be stringified.
 * @returns A `RequestInit` object configured for use with the fetch API.
 */
function createAjaxConfig(method: requestHttpType, body: object | null): RequestInit {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: body ? JSON.stringify(body) : undefined,
    signal: AbortSignal.timeout(config.timeout),
    credentials: import.meta.env.MODE === 'production' ? 'include' : undefined
  }
}

/**
 * Handles the response from a fetch request.
 *
 * This function checks if the response from the fetch request is successful. If the response
 * is not successful (i.e., the response status is not OK), it attempts to parse the response
 * body as JSON to throw it as an error. For a 204 No Content response, it returns a specific
 * object indicating the absence of content. Otherwise, it returns the response body parsed as JSON.
 *
 * @param response - The response object from a fetch request.
 * @returns A Promise that resolves to a `jsonResponseInterface` object containing the parsed JSON response
 *          body, or an object indicating a 'no-content' status for a 204 response.
 * @throws An error object parsed from the response body if the response is not successful.
 */
async function handleResponse(response: Response): Promise<jsonResponseInterface> {
  if (!response.ok) {
    const error: AppErrorInterface = await response.json()
    throw error
  }

  if (response.status === 204) {
    return { status: 'no-content' }
  }

  return response.json()
}

/**
 * Wraps the asynchronous fetch operation with additional error handling.
 * This function constructs an AJAX configuration using the provided method and body,
 * performs a fetch request to the specified URL, and processes the response.
 * It leverages `catchAsync` for error handling, ensuring any thrown errors are caught and handled appropriately.
 *
 * @param url - The URL to which the request is sent.
 * @param method - The HTTP method to be used for the request (e.g., GET, POST, PUT, etc.).
 * @param body - An optional object to be sent as the request body. If present, it is stringified and included in the request.
 * @returns A Promise resolving to a `jsonResponseInterface` object, which represents the parsed JSON response from the server.
 */
const fetchJSON = catchAsync(
  async (
    url: string,
    method: requestHttpType,
    body: object | null = null
  ): Promise<jsonResponseInterface> => {
    // Constructs the AJAX configuration for the request.
    const ajaxConfig = createAjaxConfig(method, body)
    // Performs the fetch request with the constructed configuration.
    const response = await fetch(url, ajaxConfig)
    // Processes the response and returns the parsed JSON.
    return handleResponse(response)
  }
)

/**
 * Sends an HTTP request to a specified URL using the provided HTTP method and optional request body.
 * This function constructs the request using `fetchJSON` to perform the fetch operation.
 * After receiving the response, it checks for a notification in the response.
 * If a notification exists and the user is logged in, it updates the app's notification state
 * and fetches new user notifications.
 *
 * @param url - The URL to which the HTTP request is sent.
 * @param method - The HTTP method to be used for the request (e.g., GET, POST, PUT, etc.).
 * @param body - An optional object representing the request body. If provided, it will be included in the request.
 * @returns A Promise resolving to a `jsonResponseInterface` object containing the parsed JSON response from the server.
 */
export const sendRequest = async (
  url: string,
  method: requestHttpType,
  body: object | null = null
): Promise<jsonResponseInterface> => {
  const { appStore, userStore } = initStore('appStore', 'userStore')
  const response = (await fetchJSON(url, method, body)) as jsonResponseInterface

  if (response) {
    if (response.notification) {
      appStore.updateNotificationApp(response.notification)
      appStore.updateNavigation({ popup: true })

      if (userStore.isLoggedIn) {
        userStore.updateRefresh({ notification: true })
        await userStore.fetchUserNotifications()
      }
    }
  }

  return response
}
