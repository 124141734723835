export default [
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('@/views/TheDocumentation.vue'),
    meta: {
      title: 'Playground Api - Documentation',
      description: ''
    }
  }
]
