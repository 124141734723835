import router from '@/router/router'
import { initStore } from '@/shared/utils'
import { closeModal } from './appStore.utilities'

/**
 * Logs out the user and resets the state of the application
 * @returns {Promise<void>} Promise(void)
 */
export async function logout(): Promise<void> {
  const { userStore, apiKeysStore, usersStore } = initStore(
    'userStore',
    'apiKeysStore',
    'usersStore'
  )

  await userStore.fetchLogout()

  if (userStore.isAdmin) {
    usersStore.resetUsersStore()
    apiKeysStore.resetApiKeysStore()
  }

  userStore.resetUserStore()
  router.push('/home')
  closeModal()
}
