export default [
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/TheSignup.vue'),
    meta: {
      title: 'Playground Api - Inscription'
    }
  }
]
