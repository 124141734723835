/**
 * @description Capitalizes the first letter of a string.
 * @param {string} sentence - The string to capitalize.
 * @returns {string} The capitalized string.
 */
// export function capitalizeFirstLetter(sentence: string): string {

//   return sentence.replace(/(^\w|\.\s*\w)/g, (match) => match.toUpperCase())
// }

/**
 * @description Capitalizes the first letter of a string.
 * @param {string} sentence - The string to capitalize.
 * @returns {string} The capitalized string.
 */
export function capitalizeFirstLetter(sentence: string): string {
  // Regular expression to capture the first letter of each word and the letter after each period
  // but excluding email addresses
  const regex = /(?:^|\s)[^\s@]+@[^\s@]+\.[^\s@]+|(^\w|\.\s*\w)/g

  return sentence.replace(regex, (match) => {
    // If the match is an email address, return it in lowercase
    if (/@/.test(match)) {
      return match.toLowerCase()
    } else {
      // Otherwise, return it in uppercase
      return match.toUpperCase()
    }
  })
}
