import { createPinia } from 'pinia'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router/router'
import { setMeta } from './shared/utils'
import { appear } from './modules'
import { errorManagement } from './error/errorManagement.error'
import { sentryOptions } from './config'

const pinia = createPinia()
const app = createApp(App)

if (import.meta.env.MODE === 'production') {
  Sentry.init(sentryOptions(app))
}

/**
 * The error handler for the Vue app.
 */

router.onError((err: any) => errorManagement(err))
app.config.errorHandler = (err: any) => errorManagement(err)
window.onerror = (err: any) => errorManagement(err)

app.use(pinia)
app.use(router).directive('appear', appear)
setMeta(router)

async function initApp() {
  await router.isReady()
  app.mount('#app')
}

initApp()
