import { AppError, initStore, isAppError } from '@/shared/utils'

/**
 * A function that creates a new AppError with a timeout message.
 */
export const handleTimeoutError = (): AppError => {
  return new AppError({
    message:
      "La requête a expiré en raison d'un délai d'attente dépassé. Veuillez réessayer ultérieurement.",
    categories: 'server',
    statusCode: 500
  })
}

/**
 * A function that creates a new AppError with an abort message.
 */
export const handleAbortError = (): AppError => {
  return new AppError({
    message:
      "La requête a été interrompue par une action de votre part (bouton d'arrêt du navigateur, fermeture de l'onglet, etc.). Veuillez réessayer si nécessaire.",
    categories: 'server',
    statusCode: 500
  })
}

/**
 * A function that creates a new AppError with a type message.
 */
export const handleTypeError = (): AppError => {
  return new AppError({
    message:
      "La fonctionnalité que vous avez demandée prend plus de temps que prévu pour s'exécuter. Veuillez réessayer ultérieurement.",
    categories: 'server',
    statusCode: 500
  })
}

/**
 * Adds an error to the error store.
 *
 * @param err the error to be added
 */
export function handleErrorProd(err: any) {
  let error = err

  if (!isAppError(error)) {
    error = new AppError({
      message:
        'Une erreur réseau est survenue, veuillez vérifier votre connexion Internet et réessayer. Si le problème persiste, veuillez contacter le support technique.',
      categories: 'server',
      statusCode: 500
    })
  }

  const { errorStore } = initStore('errorStore')
  errorStore.addErrors(error)
}

/**
 * Returns an object containing the name, message, and stack of the provided error.
 * This is intended for use in development environments, where more detailed error information is helpful for debugging.
 * In production environments, it is recommended to use the handleErrorProd function instead.
 * @param err the error to be processed
 */
export function handleErrorDev(err: any) {
  let error = err
  if (!isAppError(error)) {
    error = {
      name: err.name,
      message: err.message,
      stack: err.stack
    }
  }
  console.log(error)
  const { errorStore } = initStore('errorStore')
  errorStore.addErrors(error)
  return error
}
