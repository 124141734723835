import type { modalType } from '../types/types'

export interface modalInterface {
  type: modalType | null
  title: string | null
  message: string | null
  id: {
    idUser: string
    idApi: string
    idNotification: string
  }
}

export interface updateModalInterface {
  type: modalType
  title: string
  message: string
  id?: {
    idUser?: string
    idApi?: string
    idNotification?: string
  }
}

export const DEFAULT_MODAL: modalInterface = {
  type: null,
  title: null,
  message: null,
  id: {
    idUser: '',
    idApi: '',
    idNotification: ''
  }
}
