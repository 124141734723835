import type { vueEnvType } from '@/shared/types/types'

import {
  handleAbortError,
  handleErrorProd,
  handleErrorDev,
  handleTimeoutError,
  handleTypeError
} from '.'

/**
 * Manages errors in the application.
 *
 * @param err the error object
 * @returns the managed error
 */
export function errorManagement(err: any) {
  let error = err

  const nodeEnv = import.meta.env.MODE as vueEnvType

  if (nodeEnv === 'development') {
    error = handleErrorDev(error)
    console.error(error)
  } else if (nodeEnv === 'production') {
    if (error.name === 'TimeoutError') error = handleTimeoutError()
    if (error.name === 'AbortError') error = handleAbortError()
    if (error.name === 'TypeError') error = handleTypeError()

    handleErrorProd(error)
  }

  return error
}
