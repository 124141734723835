import * as Sentry from '@sentry/vue'
import type { Options, TracingOptions, Vue } from '@sentry/vue/types/types'

/**
 * Returns the Sentry options for the Vue app.
 * @param app the Vue app or array of apps
 */
export function sentryOptions(app: Vue | Vue[]):
  | Partial<
      Omit<Options, 'tracingOptions'> & {
        tracingOptions: Partial<TracingOptions>
      }
    >
  | undefined {
  return {
    app,
    dsn: import.meta.env.VITE_DSN_SENTRY,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://www.playground-api.lc-laignel.tech',
      'https://www.playground-api-connect.lc-laignel.tech'
    ],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }
}
